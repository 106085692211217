import React, {useState, useEffect, useRef} from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

const CustomForm = ({ name,
                      email,
                      company,
                      referral,
                      companyPosition,
                      feedbackMessage,
                      labelMessage,
                      success,
                      failure,
                      setName,
                      setEmail,
                      setCompany,
                      setCompanyPosition,
                      setReferral,
                      setFeedbackMessage,
                      setLabelMessage,
                      setSuccess,
                      setFailure,
                      feedback, setFeedback, status, message, onValidated }) => {
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (status === "sending") {
      setFeedback(true)
      setSubmitting(true)
      setLabelMessage('Enviando...')
    }

    if (status === "success") {
      setFeedback(true)
      setSubmitting(false)
      setSuccess(true)
      setLabelMessage('Deu tudo certo! Registramos seus dados.')
    }

    if (status === "error") {
      setFeedback(true)
      setSubmitting(false)
      setFailure(true)
      if (message.match('is already subscribed to list')) {
        setLabelMessage('Este e-mail já foi cadastrado na nossa lista.')
      } else {
        setLabelMessage('Tivemos um erro inesperado. Tente novamente mais tarde.')
      }
    }
  }, [status])

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
    email.indexOf("@") > -1 &&
    name &&
    name.length > 3 &&
    company && companyPosition && referral &&
    onValidated({
      EMAIL: email,
      NAME: name,
      COMPANY: company,
      POSITION: companyPosition,
      REFERRAL: referral,
      MESSAGE: feedbackMessage
    });
  }

  const validateInput = (email: string, name: string,
                         company: string, companyPosition: string, referral: string) =>
    (email === '' || email.indexOf('@') === -1) ||
    (name === '' || name.length < 3) ||
    (company === '' || referral === '' || companyPosition === '')


  return (
    <>
      {
        !failure && !success &&
        <form
          className={`mail-list-form ${feedback ? "feedback" : ""}`}
          onSubmit={(e) => handleSubmit(e)}>
          <p className="contact-form-title">Venha ser nosso parceiro</p>
          <div className="name-container">
              <label htmlFor='name'>Nome completo</label>
              <input
                onChange={(e) => setName(e.target.value)}
                type='text'
                value={name}
                className='name-input'
                name='name'
                required
              />
            </div>

          <div className="company-container">
            <label htmlFor='company'>Empresa</label>
            <input
              onChange={(e) => setCompany(e.target.value)}
              type='text'
              value={company}
              className='company-input'
              name='company'
              required
            />
          </div>

          <div className="company-position-container">
            <label htmlFor='companyPosition'>Cargo</label>
            <input
              onChange={(e) => setCompanyPosition(e.target.value)}
              type='text'
              value={companyPosition}
              className='company-position-input'
              name='companyPosition'
              required
            />
          </div>

          <div className="email-input-container">
            <label htmlFor='email-input'>E-mail</label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              type='email'
              value={email}
              className='email-input'
              name='email'
              required
            />
          </div>

          <div className="referral-container">
            <label htmlFor='referral'>Como conheceu a Dinerama?</label>
            <select className='referral'
                    name='referral'
                    required value={referral} onChange={(e) => setReferral(e.target.value)}>
              <option value="Google">Google</option>
              <option value="Amigos">Amigos</option>
              <option value="Familiares">Familiares</option>
              <option value="Empreendedores">Empreendedores</option>
              <option value="Outros">Outros</option>
            </select>
          </div>

          <div className="feedback-message-container">
            <label htmlFor='email-input'>Mensagem <span>(opcional)</span></label>
            <textarea
              onChange={(e) => setFeedbackMessage(e.target.value)}
              value={feedbackMessage}
              className='feedback-message'
              name='feedback-message' />
          </div>

          <div className="submit-button-container">
            <input
              className='submit-button'
              type='submit'
              value='Enviar'
              disabled={validateInput(email, name, company, companyPosition, referral) || submitting}
            />
          </div>
        </form>
      }
      <p className="message">
        {labelMessage}
      </p>
    </>
  )
}

const MailchimpContactForm = ({modalRef}) => {
  const [feedback, setFeedback] = useState(false)

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [company, setCompany] = useState('')
  const [referral, setReferral] = useState('')
  const [companyPosition, setCompanyPosition] = useState('')
  const [feedbackMessage, setFeedbackMessage] = useState('')
  const [labelMessage, setLabelMessage] = useState('')
  const [success, setSuccess] = useState(false)
  const [failure, setFailure] = useState(false)

  const postUrl = `https://gmail.us10.list-manage.com/subscribe/post?u=52600aadc5090444b965fcdd6&id=5de6db2d0c`
  const clearFields = () => {
    setEmail('')
    setName('')
    setCompany('')
    setReferral('')
    setCompanyPosition('')
    setFeedbackMessage('')
  }

  const resetForm = () => {
    clearFields()
    setLabelMessage('')
    setFeedback(false)
    setFailure(false)
    setSuccess(false)
  }

  const closeContactFormModal = () => {
    const modal = modalRef.current
    modal.close()

    const body = document.querySelector("body")
    body.classList.remove("overflow-y")

    resetForm()
  }

  return (
    <div className={`mail-contact-form ${feedback ? "feedback" : ""}`}>
      <div className="contact-form-close-button-wrapper">
        <span className="close-contact-modal"
              onClick={() => closeContactFormModal()}>&times;</span>
      </div>
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            name={name}
            email={email}
            company={company}
            companyPosition={companyPosition}
            referral={referral}
            failure={failure}
            success={success}
            feedbackMessage={feedbackMessage}
            labelMessage={labelMessage}
            setName={setName}
            setEmail={setEmail}
            setCompany={setCompany}
            setCompanyPosition={setCompanyPosition}
            setReferral={setReferral}
            setFeedbackMessage={setFeedbackMessage}
            setLabelMessage={setLabelMessage}
            setSuccess={setSuccess}
            setFailure={setFailure}
            feedback={feedback}
            setFeedback={setFeedback}
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)} />
        )}
      />
    </div>
  )
}

export default MailchimpContactForm