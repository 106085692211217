import React from "react"

export default function Insights({openContactModal}) {
  return (
    <div className="partners-insights container">
      <h2 className="title"><span className="paint-brush">Seus insights</span> como você nunca pensou</h2>
      <p className="description text-on-the-right">Destravamos a complexidade e o alto custo do acesso a dados e análises para a sua marca. A Dinerama consegue ter dados granulares de compras dos shoppers em tempo real e em todo o Brasil, sem intermediários.</p>
      <a className="btn detailed-data-button" onClick={() => openContactModal()}>Quero ter dados detalhados</a>
      <img className="partners-insights-image image" src="/images/partners/_2.svg" alt="insights"></img>
    </div>
  )
}