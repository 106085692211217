import React from "react"

export default function Shoppers({openContactModal}) {
  return (
    <div className="partners-shoppers container">
      <h2 className="title"><span className="paint-brush">Seus shoppers</span> como você nunca conheceu</h2>
      <p className="description">Tiramos a sua barreira para conhecer os seus consumidores. A Dinerama tem dados proprietários de sellout e comportamento dos shoppers para a sua marca se tornar shopper centric.</p>
      <a className="btn know-your-shoppers-button" onClick={() => openContactModal()}>Quero conhecer meu consumidor</a>
      <img className="shoppers-image image" src="/images/partners/_3.svg" alt="shoppers"></img>
    </div>
  )
}