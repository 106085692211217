import React from "react"

export default function PartnersGallery({openContactModal}) {
  return (
    <div className="partners-set container">
      <h2 className="title">Nossos parceiros já <span className="highlighted-yellow">estão ganhando</span></h2>
      <p className="description">Junte-se a essas empresas que confiam na Dinerama.</p>
      <div className="logos">
        <img className="unilever" src="/images/unilever.svg" alt="logo da Unilever" />
        <img className="heineken" src="/images/heineken.svg" alt="logo da Heineken" />
        <img className="colgate-logo" src="/images/colgate.svg" alt="logo da Colgate" />
        <img className="ambev-logo" src="/images/ambev.svg" alt="logo da Ambev" />
        <img className="montana-logo" src="/images/montana.svg" alt="logo da Montana" />
        <img className="bemfresco-logo" src="/images/bem_fresco.svg" alt="logo da Bem Fresco" />
      </div>
      <a className="btn want-part-of-team-button" onClick={() => openContactModal()}>Quero fazer parte desse time</a>
    </div>
  )
}