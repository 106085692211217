import Seo from "../seo";
import React, {useEffect, useRef} from "react"
import Header from "../components/partners/header"
import Hero from "../components/partners/hero"
import Insights from "../components/partners/insights"
import Shoppers from "../components/partners/shoppers"
import ROI from "../components/partners/roi"
import PartnersGallery from "../components/partners/partners_set"
import Footer from "../components/partners/footer"
import ContactFormModal from "../components/partners/contact-form-modal";

export default function Partners() {
  const modalRef = useRef(null)
  const changeHeaderColorWhenScrolling = () => {
    const partnersRoot = document.querySelector("#partners-root")

    const isInViewport = (rect) => {
      return rect.top >= -400 && rect.left >= 0 &&
        rect.bottom <= ((window.innerHeight + 400) || (document.documentElement.clientHeight + 400)) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    }

    const handleScroll = _ => {
      const header = document.querySelector("#partners-root .partners-header")
      const logo = document.querySelector("#partners-root .partners-header .logo")
      const logoInverted = document.querySelector("#partners-root .partners-header .logo-inverted")
      const partners = document.querySelector("#partners-root .partners-set")
      const footer = document.querySelector("#partners-root .partners-footer")

      const partnersRect = partners.getBoundingClientRect()
      const footerRect = footer.getBoundingClientRect()

      if (isInViewport(partnersRect) || isInViewport(footerRect)) {
        header.classList.add("inverted-header")
        logoInverted.classList.remove("logo-invisible")
        logo.classList.add("logo-invisible")
      } else {
        header.classList.remove("inverted-header")
        logo.classList.remove("logo-invisible")
        logoInverted.classList.add("logo-invisible")
      }
    }

    partnersRoot.addEventListener("wheel", handleScroll, false)
  }

  const openContactModal = (modalRef) => {
    const modal = modalRef.current
    modal.showModal()

    const body = document.querySelector("body")
    body.classList.add("overflow-y")
  }

  useEffect(() => {
    changeHeaderColorWhenScrolling()
  }, [])

  return (
    <div id="partners-root">
      <ContactFormModal modalRef={modalRef} />
      <Seo title="Cashback em qualquer supermercado" description="Colocamos o poder de escolha nas suas mãos. Com a Dinerama, ganhar dinheiro de volta nas compras do dia-a-dia nunca foi tão simples."/>
      <section className="main">
        <Header openContactModal={() => openContactModal(modalRef)} />
        <Hero openContactModal={() => openContactModal(modalRef)} />
        <Insights openContactModal={() => openContactModal(modalRef)} />
        <Shoppers openContactModal={() => openContactModal(modalRef)} />
        <ROI openContactModal={() => openContactModal(modalRef)} />
        <PartnersGallery openContactModal={() => openContactModal(modalRef)} />
        <Footer />
      </section>
    </div>
  )
}