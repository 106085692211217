import React from "react";
import MailchimpContactForm from "./mailchimp-contact-form";

export default function ContactFormModal({modalRef}) {

  return (
    <dialog id="contactForm" className="contact-form" ref={modalRef}>
      <MailchimpContactForm modalRef={modalRef} />
    </dialog>
  )
}