import React from "react"

export default function ROI({openContactModal}) {
  return (
    <div className="partners-roi container">
      <h2 className="title"><span className="paint-brush">Seu ROI</span> como você nunca imaginou</h2>
      <p className="description">Não conseguir atribuir uma venda in-store a uma ação de marketing ficou no passado. A Dinerama é tech e tem a assertividade de conexões 1:1 que geram vendas incrementais concretas para sua marca.</p>
      <a className="btn improve-selling-actions-button" onClick={() => openContactModal()}>Quero melhorar minhas ações de venda</a>
      <img className="partners-roi-image image" src="/images/partners/_4.svg" alt="roi"></img>
    </div>
  )
}