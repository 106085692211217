import React from "react"

export default function Footer() {
  return (
    <div className="partners-footer container">
      <h2 className="title">Por que o cashback aumenta o <span className="highlighted-yellow">engajamento</span> e as <span className="highlighted-yellow">vendas</span></h2>
      <div className="reasons-cashback-increases-results">
        <div className="reason-item">
          <h3 className="reason-title">01.</h3>
          <p className="description">42% dos brasileiros consideram <span className="highlighted-yellow bold">cashback</span> o <span className="highlighted-yellow bold">melhor benefício</span> para a sua fidelidade.</p>
        </div>
        <div className="reason-item">
          <h3 className="reason-title">02.</h3>
          <p className="description">53% afirmam que o <span className="highlighted-yellow bold">cashback influencia suas decisões</span> de compras.</p>
        </div>
        <div className="reason-item">
          <h3 className="reason-title">03.</h3>
          <p className="description">Ofertas com <span className="highlighted-yellow bold">cashback aumentam</span> em 46% o valor do <span className="highlighted-yellow bold">ticket médio</span> de uma compra.</p>
        </div>
        <div className="reason-item">
          <h3 className="reason-title">04.</h3>
          <p className="description">84% dos <span className="highlighted-yellow bold">millennials</span> usam o <span className="highlighted-yellow bold">celular</span> para auxiliá-los em compras <span className="highlighted-yellow bold">dentro de loja.</span></p>
        </div>
      </div>
    </div>
  )
}