import React from "react"
import {Link} from "gatsby";

export default function Header({openContactModal}) {
  return (
    <div className="partners-header">
        <a className="logo" href="http://www.dinerama.com.br">
          <img src="/images/logo-name_4.svg" alt="dinerama logo"></img>
        </a>
        <a className="logo-inverted logo-invisible" href="http://www.dinerama.com.br">
          <img src="/images/logo-name_3.svg" alt="dinerama logo branco"></img>
        </a>
        <div className="menu-toggle-wrapper">
          <input id="partners-menu-toggle" type="checkbox"/>
          <label className='menu-button-container' htmlFor="partners-menu-toggle">
            <div className='menu-button'></div>
          </label>
          <ul className="partners-mobile-menu-list">
            <li><Link to="/">Dinerama</Link></li>
            <li>
              <a className="pointer-link"
                onClick={() => openContactModal()}>Entrar em contato</a>
            </li>
          </ul>
        </div>

        <ul className="partners-menu-list">
          <li><Link to="/">Dinerama</Link></li>
        </ul>
        <a className="btn contact-us-button" onClick={() => openContactModal()}>Entrar em contato</a>
    </div>
  );
}