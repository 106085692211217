import React from "react"

export default function Hero({openContactModal}) {
  return (
    <div className="partners-hero container">
      <span className="ornament"></span>
      <h2 className="title"><span className="paint-brush">Suas promoções</span> como você nunca viu</h2>
      <p className="description text-on-the-left">Eliminamos a ineficiência do processo de criação de promos nacionais da sua marca. A Dinerama é uma única solução de ponta a ponta que entrega desde o setup da sua campanha até o pagamento de cashback ao shopper.</p>
      <a className="btn create-campaign-button" onClick={() => openContactModal()}>Quero criar minha campanha</a>
      <img className="hero-image image" src="/images/partners/_1.svg" alt=""></img>
    </div>
  )
}
